mat-list,
mat-nav-list {
  mat-icon {
    // override the default color of the icon which is a very light gray
    // to be our foreground text color so it matches the text in the list
    --mdc-list-list-item-leading-icon-color: var(--foreground-text);
    --mdc-list-list-item-hover-leading-icon-color: var(--foreground-text);
  }

  // We have to do all this to override the default styling for activated list items
  .mdc-list-item--selected,
  .mdc-list-item--activated {
    background-color: var(--primary-default) !important;

    --mdc-list-list-item-supporting-text-color: var(--primary-default-contrast);

    & .mdc-list-item__primary-text,
    & .mdc-list-item__primary-text {
      color: var(--primary-default-contrast) !important;
    }

    &.mdc-list-item--with-leading-icon .mdc-list-item__start,
    &.mdc-list-item--with-leading-icon .mdc-list-item__start {
      color: var(--primary-default-contrast) !important;
    }
  }
}

mat-nav-list[dense] {
  mat-icon[matlistitemicon] {
    margin-left: 8px;
    margin-right: 8px;
  }
}
