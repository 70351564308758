.print-content{
    display: none;
}

@media print {

    .mat-app-background,
    .content-container.print-background{
      print-color-adjust: exact;
      background-color: #ffffff !important;
    }

    .print-content{
        display: block;
        print-color-adjust: exact;
        background-color: #ffffff;
    }

    .print-page{
        print-color-adjust: exact;
        page-break-after: always;
        background-color: #ffffff;
    }

    .hide-print{
        display: none;
    }

    // hide one trust cookie policy on print
    #onetrust-consent-sdk{
      display: none;
    }
}

// tsq chart styling classes
.cls-21, .cls-3, .cls-38 {
    fill: white;

    @media print {
      fill: black;
    }
  }
  
  .cls-31 {
    stroke: white;
    
    @media print {
      stroke: black;
    }
  }
