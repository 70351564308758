@use 'media';

:root {
  --grid-gap: var(--spacing-2x);
  --list-gap: var(--spacing-1x);

  @include media.media-breakpoint-up('small') {
    --grid-gap: var(--spacing-3x);
    --list-gap: var(--spacing-2x);
  }
}
