/// Get the full font config from a material typography level
/// Example 'body-1' returns:
///
/// font-size: 16px;
/// line-height: 24px;
/// font-weight: 400;
/// font-family: Roboto, sans-serif;
/// letter-spacing: 0.03125em;
@mixin getFullFontConfig($level) {
  font-size: var(--#{$level}-font-size);
  line-height: var(--#{$level}-line-height);
  font-weight: var(--#{$level}-font-weight);
  font-family: var(--#{$level}-font-family);
  letter-spacing: var(--#{$level}-letter-spacing);
}

// 100
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

// 300
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// 400
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// 500
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

// 700
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

// 900
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

// Material icons
@import 'material-design-icons-iconfont/dist/material-design-icons.css';
