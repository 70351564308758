#share-image-content{
    display: none;
    width:500px;
    height:260px;
    text-align: center;
    background-color: #ffffff;
    padding: var(--spacing-2x);
    position: fixed;
    left: -10000px;
    top: 0px;
    border: 0px;
}

.share-container{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}