@use 'media';

mat-button-toggle-group {
  // styling for answers
  &.variation-column {
    --button-height: 36px;

    display: flex;
    flex-direction: column;
    border: none;
    width: 70%;
    border-radius: 0;

    @include media.media-breakpoint-down('x-small') {
      width: 100%;
    }

    mat-button-toggle {
      width: 100%;
      height: auto;
      min-height: var(--button-height);

      .mat-button-toggle-button{
        border: 1px solid var(--mat-divider-color);
        height: 100%;
        min-height: var(--button-height);
      }

      .mat-button-toggle-label-content {
        line-height: normal;
        padding: var(--spacing-1x);
        text-wrap: wrap;
      }

      // answered styling
      &.mat-button-toggle-disabled.mat-button-toggle-checked,
      &.mat-button-toggle-checked{

        .mat-button-toggle-button{
          border-color: var(--accent-default);
          background-color: var(--accent-default);
          color: var(--accent-800-contrast);
        }
      }
    }

    .mat-button-toggle-appearance-standard+.mat-button-toggle-appearance-standard{
      border-left: none;
    }

    mat-button-toggle + mat-button-toggle {
      margin-top: var(--spacing-3x);
    }
  }

  &.variation-row {
    --button-height: 36px;

    display: flex;
    border: none;
    width: 100%;
    border-radius: 0;

    mat-button-toggle {
      width: 100%;
      height: auto;
      min-height: var(--button-height);

      .mat-button-toggle-button{
        border: 1px solid var(--mat-divider-color);
        height: 100%;
        min-height: var(--button-height);
      }

      .mat-button-toggle-label-content {
        line-height: normal;
        padding: var(--spacing-1x);
        text-wrap: wrap;
      }

      // answered styling
      &.mat-button-toggle-disabled.mat-button-toggle-checked,
      &.mat-button-toggle-checked{

        .mat-button-toggle-button{
          border-color: var(--accent-default);
          background-color: var(--accent-default);
          color: var(--accent-800-contrast);
        }
      }
    }

    .mat-button-toggle-appearance-standard+.mat-button-toggle-appearance-standard{
      border-left: none;
    }
  }
}
