@use 'media';

.cat-dialog {
  height: 720px;
  width: 540px;

  @include media.media-breakpoint-down('x-small') {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
  }
}

.sis-cat-backdrop {
  background-color: #fff;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: url('/assets/images/assessments/skills-in-sight/sis_background.svg') no-repeat center center;
    background-size: cover;
  }
}

.mci-cat-backdrop {
  background-color: #fff;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: url('/assets/images/assessments/my-career-insights/my_career_insight_background.svg') no-repeat center
      center;
    background-size: cover;
  }
}
