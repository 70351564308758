@mixin initStgDarkThemeOverrides() {

    --mdc-elevated-card-container-color: var(--dark-primary);
    --mdc-filled-text-field-container-color: var(--dark-primary);
    --button-border-radius: 4px;

    --question-highlight: var(--primary-500);

    mat-button-toggle-group {

        // styling for answers
        &.variation-column {
          width: 100%;
      
          mat-button-toggle {
            border-radius: var(--button-border-radius);
            background-color: transparent;
      
            .mat-button-toggle-button{
              background-color: transparent;
              border: 1px solid var(--question-highlight);
              border-radius: var(--button-border-radius);
            }

            .mat-button-toggle-ripple,
            .mat-button-toggle-focus-overlay{
                border-radius: var(--button-border-radius);   
            }
      
            // answered styling
            &.mat-button-toggle-disabled.mat-button-toggle-checked,
            &.mat-button-toggle-checked{
      
              .mat-button-toggle-button{
                background-color: var(--question-highlight);
                color: var(--primary-500-contrast);
              }
            }
          }
        }
      }

      // game questions 
      .first-game{
        --question-highlight: var(--primary-500);
      }
  
      .second-game{
        --question-highlight: var(--accent-500);
      }
  
      .third-game{
        --question-highlight: var(--game-green);
      }
  
      .text-highlight{
        color: var(--question-highlight);
      }
}
