@use '@angular/material' as mat;
@use './utils/theme-init' as themeInit;

@use 'themes' as themes;

@use '../theme-overrides/gear-theme-overrides' as gear-theme-overrides;

@use '../theme-overrides/stg-theme-overrides' as stg-theme-overrides;

@use '../theme-overrides/tsq-theme-overrides' as tsq-theme-overrides;

@include mat.core();

@include themeInit.initCoreThemeColors(themes.$manpower-light-theme);

// gear assessment theme
[data-theme="gear"] {
    @include themeInit.initAlternateThemeColors(themes.$gear-light-theme);
    @include gear-theme-overrides.initGearThemeOverrides();
}


// stg assessment dark theme
[data-theme="stg-dark"] {
    @include themeInit.initAlternateThemeColors(themes.$stg-dark-theme);
    @include stg-theme-overrides.initStgDarkThemeOverrides();
}


// tsq assessment dark theme
[data-theme="tsq-dark"] {
    @include themeInit.initAlternateThemeColors(themes.$tsq-dark-theme);
    @include tsq-theme-overrides.initTsqDarkThemeOverrides();
}