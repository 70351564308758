mat-form-field:not(.no-bottom-space) {
  margin-bottom: var(--spacing-1x);
}

.hint {
  padding: 0 var(--spacing-2x) var(--spacing-1x);
}

.password-pattern-error {
  color: var(--mdc-theme-error, #f44336);
}

fieldset {
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  border-color: var(--mdc-outlined-text-field-outline-color);

  &:hover {
    border-color: var(--foreground-text);
  }
}
