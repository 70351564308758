/* Info */
.mat-mdc-snack-bar-container.snackbar-info .mdc-snackbar__surface {
  background-color: var(--alert-info);

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--alert-contrast-info);
  }
}

/* Success */
.mat-mdc-snack-bar-container.snackbar-success .mdc-snackbar__surface {
  background-color: var(--alert-success);

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--alert-contrast-success);
  }
}

/* Warning */
.mat-mdc-snack-bar-container.snackbar-warning .mdc-snackbar__surface {
  background-color: var(--alert-warn);

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--alert-contrast-warn);
  }
}

/* Error */
.mat-mdc-snack-bar-container.snackbar-error .mdc-snackbar__surface {
  background-color: var(--alert-error);

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--alert-contrast-error);
  }
}
