@use 'sass:map';
@use '@angular/material' as mat;

@mixin makeCSSVariablesFromTheme($theme) {
  $color-config: mat.get-color-config($theme);
  $typography-config: mat.get-typography-config($theme);
  $alert-config: map.get($theme, alert);

  @each $palette-name, $palette in $color-config {
    @include makeCSSVariablesFromConfig($palette, $palette-name);
  }

  @each $typography-name, $typography in $typography-config {
    @include makeCSSVariablesFromConfig($typography, $typography-name);
  }

  @include makeCSSVariablesFromConfig($alert-config, 'alert', true);
}

/// Goes through the map and generates CSS variables for each [key, value] in the map
/// By default it doesn't go into maps if there are any in the config.
///
/// For example the material palette map has an extra `contrast` map inside.
/// Since the material palette already "extracts" its contrast map values 
/// to the parent level when using `mat.define-palette()` function, if you 
/// use the $deep on it, it will generate double contrast values.
///
/// So it is only recommended to set the $deep, if you have a custom config that has a
/// map inside that you want to generate values of.
///
/// Example:
/// ```
/// If we have a map like this
/// $someMap: (
///   color1: red,
///   color2: blue,
/// )
///
/// @include makeCSSVariablesFromConfig($someMap, 'our-map');
/// ```
///
/// it will generate the following CSS variables:
/// --our-map-color1: red;
/// --our-map-color2: blue;
///
@mixin makeCSSVariablesFromConfig($map, $name, $deep: false) {
  @each $key, $value in $map {
    $var-name: $name + '-' + $key;

    @if $deep == true {
      @if type-of($value) == 'map' {
        @include makeCSSVariablesFromConfig($value, $var-name);
      } @else if $value != null {
        --#{$var-name}: #{$value};
      }
    } @else {
      @if type-of($value) != 'map' and $value != null {
        --#{$var-name}: #{$value};
      }
    }
  }
}
