@use '@angular/material' as mat;
@use 'make-css-variables-from-theme' as make;
@use 'custom-component-themes' as custom-components;

@mixin initCoreThemeColors($theme) {
  @include mat.core-theme($theme);
  @include mat.all-component-themes($theme);
  @include mat.typography-hierarchy($theme);
  @include custom-components.all-custom-component-themes($theme);

  // :roots needs to be used here as otherwise we get:
  // "SassError: Declarations may only be used within style rules."
  :root {
    @include make.makeCSSVariablesFromTheme($theme);
  }
}

@mixin initAlternateThemeColors($theme, $includeVarsInRoot: false) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.core-color($theme);
  @include mat.all-component-colors($theme);
  @include custom-components.all-custom-component-themes($theme);

  // if the theme is set without a class, for example: with a @media rule,
  // then we need to initialize the CSS vars in the :root
  @if $includeVarsInRoot {
    :root {
      @include make.makeCSSVariablesFromTheme($theme);
    }
  } @else {
    @include make.makeCSSVariablesFromTheme($theme);
  }
}
