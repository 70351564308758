@use 'media';

@mixin initTsqDarkThemeOverrides() {

    --highlight: var(--dark-primary);

    --highlight-blue: #4c79af;
    --highlight-orange: #c25721;
    --highlight-green: #8fc3a9;
    --highlight-contrast: #ffffff;
    --container-inner-padding: 56px;
    --button-border-radius: 20px;

    // material variables
    --mdc-elevated-card-container-color: var(--background-background);
    --mdc-filled-text-field-container-color: var(--background-background);
    

    // material classes
    .mdc-button{
        --mdc-protected-button-container-shape: var(--button-border-radius);
        --mdc-outlined-button-container-shape: var(--button-border-radius);
        --mdc-filled-button-container-shape: var(--button-border-radius);
    }

    .mdc-button.highlight-btn{
        padding: var(--spacing-2x) var(--spacing-4x);
        border-radius: var(--button-border-radius);
        background-color: var(--highlight);
        color: var(--highlight-contrast);
        font-size: 19px;
        line-height: 1.2;
        height: auto;
    }

    .mat-mdc-radio-button.highlight-radio-btn{
        --mdc-radio-selected-icon-color: var(--highlight);
        --mdc-radio-selected-focus-icon-color: var(--highlight);
        --mdc-radio-selected-hover-icon-color: var(--highlight);
        --mdc-radio-selected-pressed-icon-color: var(--highlight);
        --mat-radio-checked-ripple-color: var(--highlight);

        &.mat-mdc-radio-checked{
            --mdc-radio-disabled-selected-icon-color: var(--highlight);
        }
    }

    // text classes
    .text-lg{
        font-size: 27px;
        line-height: 1.2;
        margin-bottom: var(--spacing-5x);

        @include media.media-breakpoint-down('small') {
            font-size: 24px;
        }
    }

    .text-md{
        font-size: 19px;
        line-height: 1.2;
        margin-bottom: var(--spacing-5x);
    }
    

    // highlight classes for particular part of the assessment 
    .highlight-default{
        --highlight: var(--dark-primary);
    }

    .highlight-blue{
        --highlight: var(--highlight-blue);
    }

    .highlight-orange{
        --highlight: var(--highlight-orange);
    }

    .highlight-green{
        --highlight: var(--highlight-green);
    }
}
