@mixin initGearThemeOverrides() {

    --button-width: 160px;
    --button-height: 50px;
    --button-border-radius: 30px;
    
    --mdc-outlined-text-field-container-shape: var(--button-border-radius);
    --mat-standard-button-toggle-shape: var(--button-border-radius);

    .mdc-button{
        --mdc-protected-button-container-shape: var(--button-border-radius);
        min-width: var(--button-width);
        height: var(--button-height);
        border-radius: var(--button-border-radius);
    }

    p {
        font-size: 16px;
    }

    mat-button-toggle-group {

        // styling for answers
        &.variation-column {
          --button-height: 50px;

          width: 100%;
      
          mat-button-toggle {
            border-radius: var(--button-border-radius);
      
            .mat-button-toggle-button{
              background-color: var(--toggle-button-primary);
              border: 1px solid transparent;
              border-radius: var(--button-border-radius);
            }

            .mat-button-toggle-ripple,
            .mat-button-toggle-focus-overlay{
                border-radius: var(--button-border-radius);   
            }
      
            // answered styling
            &.mat-button-toggle-disabled.mat-button-toggle-checked,
            &.mat-button-toggle-checked{
      
              .mat-button-toggle-button{
                border-color: var(--primary-500);
                background-color: var(--primary-100);
                color: var(--primary-100-contrast);
              }
            }
          }
        }
      }
}
