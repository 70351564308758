/* Standard spacing between elements within group, e. g. in form. */
.mb-standard {
  margin-bottom: var(--spacing-2x) !important;
}

.mt-standard {
  margin-top: var(--spacing-2x) !important;
}

.display-inline {
  display: inline;
}

.align-self-start {
  align-self: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-align-center {
  display: flex;
  align-items: center;
}

.space-between {
  justify-content: space-between !important;
}

.inline-flex {
  display: inline-flex;
}

.align-items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.normal-font-weight {
  font-weight: normal !important;
}

.two-line-text-limit {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.center-text {
  text-align: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width,
.w100p {
  width: 100%;
}
